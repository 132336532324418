<template>
  <div class="add-new-category">
    <title-bar
      submitBtn
      submitBtnTitle="Add category"
      title="Create category"
      @submitPressed="submitPressed"
    />
    <category-form :categoryObject="category" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import CategoryForm from './components/CategoryForm.vue'

export default {
  components: {
    TitleBar,
    CategoryForm
  },
  data() {
    return {
      action: null,
      category: {
        name: '',
        description: '',
        keywords: '',
        metaDescription: '',
        url: '',
        parent: { label: 'Not selected', id: null }
      },
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    }
  },
}
</script>
